<template>
<div class="min-h-screen flex flex-col w-full">
    <LayoutHeader/>
    <div class="mx-auto w-full">
        <slot/>
    </div>
    <LayoutFooter/>
    <div v-if="backdropActive" @click="backdropActive = false" class="shared-backdrop bg-black opacity-10 fixed z-40 h-full w-full"></div>
</div>
</template>
<script lang="ts">
export default {
    name: "DefaultLayout",
};
</script>
<script setup lang="ts">

const backdropActive = ref(false);
provide("backdropActive", backdropActive);

const {loadNavigationElements} = useNavigation();
const {data: mainData} = await useAsyncData("mainNavigation", () => {
    return loadNavigationElements({depth: 2});
});
provide("swNavigation-main-navigation", mainData);

const { loadNavigationElements: loadFooterNavigationElements } = useNavigation({type: "footer-navigation"});
const { data: footerData } = await useAsyncData("mainFooterNavigation", () => {
    return loadFooterNavigationElements({ depth: 2 });
});
provide("swNavigation-footer-navigation", footerData);

const {loadNavigationElements: loadServiceNavigationElements} = useNavigation({type: "service-navigation"});
const {data: serviceData} = await useAsyncData("mainServiceNavigation", () => {
    return loadServiceNavigationElements({depth: 2});
});
provide("swNavigation-service-navigation", serviceData);

</script>
